@import "colours";

html {
  height: 100%;
}

body, #root {
  background: $background;	
  flex-grow: 1;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  font-family: "Avenir", "HelveticaNeue", "Helvetica Neue", Helvetica, 'Roboto', Arial, sans-serif;
}

.app-shell {
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;	

	@media (max-width: 650px) { 
	    flex-direction: column-reverse;
	}
}

.app-shell__content {
  flex-grow: 1;
  display: flex;
  height: calc( 100% - 50px );
  position: relative;
}

.transition-wrapper {
	flex-grow: 1;
	display: flex;
	width: 100%;
	height: 100%;

	&>div { 
		flex-grow: 1;
		display: flex;
		width: 100%;
		height: 100%;
	}
}

.app-shell__content-wrapper {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll; 
    -webkit-overflow-scrolling: touch;
}

.app-shell__navigation {
	background: $white;
	box-sizing: border-box;
	box-shadow: 0 -6px 10px #540E5E;
	z-index: 10;
	animation-fill-mode: backwards;

	@media (max-width: 650px) { 
		box-shadow: 0 4px 10px #000;	
	}
}

.page-main {
	position: relative;
}

.app-shell__nav-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	padding: 0px 20px;
	overflow: hidden;
}

.app-shell__nav-icon-link {
	height: 25px;
	padding-right: 20px;
}

.app-shell__nav-icon {
	width: 16px;

	.app-shell__nav-icon-link &:hover {
		fill: #F191F4;
	}

	.app-shell__nav-icon-link &:active {
		fill: $dark-colour;
	}
}

.app-shell__nav-offline {
	font-size: 1.6rem;
    position: absolute;
    left: 45px;
}

.app-shell__nav-wrapper {
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transform: translate( 10px, -1px);
}

.app-shell__nav-item {
	margin: 0 0 0 5px;
	padding: 0;
	list-style: none;

	&:first-child {
		margin-left: 0;
	}
}

.app-shell__nav-link {
	text-decoration: none;
	color: $dark-colour;
	font-size: 1.6rem;
	padding: 5px 10px;
	position: relative;

	&:hover{
		color: $dark-colour;
	}

	&:after {
		display: block;
		content: '';
		position: absolute;
		top: -8px;
		left: 0;
		width: 100%;
		height: 0px;
		background: $dark-colour;
	}

	&:active {
		color: #FF0000;
	}

	&:hover:after {
		height: 4px;
	}

	&.active {
		color: $dark-colour;

		&:after {
			height: 4px;
			background: $dark-colour;
		}
	}
	
	&:active {
		color: $dark-colour;
	}

	&:active:after {
		background: $dark-colour;
	}
}

.my-node-enter,
.my-node-exit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.my-node-enter {
  opacity: 0;
  transform: translateX( 100% );
  z-index: 2;
  background: $background;

  @media (min-width: 1000px) { 
  	transform: translateY( 0 ) scale(1.1);
  }
}

.my-node-enter-active {
  opacity: 1;
  transform: translateX( 0 ) scale(1);
  transition: all 0.2s 0.1s ease-out;
}

.my-node-exit {
  opacity: 1;
  z-index: 1;

  &:after {
  	position: fixed;
  	top: -200px;
  	left: -200px;
  	width: 200%;
  	height: 200%;
  	display: block;
  	background: $black;
  	z-index: 0;	
  	opacity: 0;
  	content: '';
  }
}

.my-node-exit-active {
  transform: scale(0.95);
  opacity: 0;
  transition: transform 0.2s ease, opacity 0.1s 0.2s;  

  &.my-node-exit:after {
  	opacity: 0.6;
  	transition: all 0.2s;
  }
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes navLoad {
    0% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(0)
    }    
}

@keyframes mobileNavLoad {
    0% {
        transform: translateY(200px);
    }
    100% {
        transform: translateY(0)
    }    
}