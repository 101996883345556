.fade-appear,
.fade-enter {
  opacity: 0;
  transition: opacity 0.2s 0.3s ease-out;
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity .2s ease-out;
}