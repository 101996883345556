.content {
	color: $text;

	h1, h2, h3, h4, h5 {
		font-weight: 400;
		color: $text;
		margin: 3.2rem 0 1rem;
	}

	p {
		line-height: 1.4;
		color: $text-light;
		font-weight: 200;

		@media (min-width: 550px) {
			font-size: 2.2rem;
		}

		@media (min-width: 900px) {
			font-size: 2.4rem;
			line-height: 1.5;
		}
	}

	a:hover {
		text-decoration: none;
	}

	img {
		width: 100%;
		height: auto;
		display: block;
		box-sizing: border-box;
		opacity: 0;
      	animation: fade-in 600ms ease-in-out forwards;
		transition: opacity 600ms ease-in-out;
	}

	img:hover {
		animation: fade-in 600ms ease-in-out forwards;
	}

	@keyframes fade-in {
		to {
			opacity: 1;
		}
	}

	figure {
		margin: 0 0 2.2rem;
		width: 100% !important;
		background: #373438;
	}

	figcaption {
		margin-top: 10px;
		font-size: 1.4rem;
		line-height: 1.2em;
		font-style: italic;

		@media (min-width: 900px) {
			font-size: 1.6rem;
		}
	}
	
	ol, ul {
		line-height: 1.4;
		color: $text-light;


		@media (min-width: 550px) {
			font-size: 2.2rem;
			margin-left: 1em;
		}

		@media (min-width: 900px) {
			font-size: 2.6rem;
		}
	}

	ul li {
		list-style-type: disc;
	    list-style-position: outside;
	    margin-left: 25px;
	    color: $text-light;

	    @media (min-width: 550px) {
	    	font-size: 2.2rem;
	    }

	    @media (min-width: 900px) {
	    	font-size: 2.4rem;
	    	line-height: 1.5;
	    }
	}

	blockquote {
		font-style: oblique;
		margin-right: 0;
		margin-left: 0;
		padding-left: 1em;
		border-left: 8px solid $background__stroke;
		
		@media (min-width: 550px) {
			padding-left: 1.5em;
		}
	}

	.error_wrapper {
		border-bottom: 16px solid $background__stroke;
		padding: 3rem 0;
		margin: 3rem 0 6rem;
	}
}

.divider {
	border-top: 4px solid $white;
	padding-top: 5rem;
	margin: 6rem 0 6rem;

	@media (min-width: 550px) {
		border-width: 8px;
	}
}

.content-wrapper {
	max-width: 900px;
	margin: 0 auto;
}
