@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/avenir-light.woff2') format('woff2');
  src: url('../fonts/avenir/avenir-light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/avenir-book.woff2') format('woff2');
  src: url('../fonts/avenir/avenir-book.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/avenir-regular.woff2') format('woff2');
  src: url('../fonts/avenir/avenir-regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/avenir-heavy.woff2') format('woff2');
  src: url('../fonts/avenir/avenir-heavy.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/avenir-black.woff2') format('woff2');
  src: url('../fonts/avenir/avenir-black.woff') format('woff');
  font-weight: 900;
}

