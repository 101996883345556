@import "../../sass/components/colours";

.post-listing {
	display: block;
	border-bottom: 1px solid $background__stroke;
	text-decoration: none;
	color: $text;
	padding: 20px;

	.icon {
		display: inline-block;
		transform: translateY(25%);
		opacity: 0;
		transition: all 200ms ease-in-out;
	}

	@media (min-width: 650px) {
		padding: 40px 0; 
	}

	&:hover .featured-image-wrapper {
		background: $interactive;
	}

	.featured-image {
		transition: none;
	}

	&:hover .featured-image {
		opacity: 0.5;
		filter: grayscale(100%) contrast(200%);
	}

	&:active,
	&:active .reading-time__listing {
		color: $text;
	}

	&:hover .icon {
		opacity: 1;
		transform: translate(8px,25%);

		path {
			stroke: $interactive--dark;
		}
	}
}

.post-listing--related {
	padding: 24px 0;
}

.post-listing--current {
	color: $interactive;
	position: relative;

	&:hover {
		cursor: default;
	}
}

.post-listing__current-dot {
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background: $interactive;
	margin-left: 8px;
	transform: translateY(-3px);
}

.post-listing__image-wrapper {
	margin-bottom: 15px;
}

.post-listing__image {
	width: 100%;
	display: inline-block;
	background: $white;
	padding: 10px;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
}

.post-listing__title {
	font-weight: 400;
	font-size: 4rem;
	line-height: 1.2;

	.post-listing--related & {
		font-size: 2rem;
	}

	.tale & {
		font-size: 2.4rem;
	}

	@media (min-width: 650px) {
		.post-listing--related & {
			font-size: 2.4rem;
		}

		font-size: 6rem; 

		.tale & {
			font-size: 3.2rem;
		}
	}
}

.post-listing__details {
	display: inline-flex;
	align-items: center;
	gap: 16px;
	margin-top: 40px;

	@media (min-width: 650px) {
		margin-top: 80px;
	}
}

.post-listing__visited {
	font-weight: normal;
	color: $white;
	font-size: 1.5rem;
}

.post-listing__date {
	font-weight: normal;
	color: $white;
	font-size: 1.5rem;
}

.post-listing__reading-time {
	font-size: 1.5rem;
	font-weight: normal;
	color: $white;
}

.post-listing__icon {
	display: inline-flex;
	align-items: center;
	gap: 4px;
	font-weight: 100;

	svg {
		transform: translateY(-1px);
	}

	@media (min-width: 650px) {
		gap: 6px;
	}
}