@import "../components/colours";
@import "../components/content";

/*= Grid
===========================================*/
.grid {
	width: 90%;
	margin: 0 auto;

	@media (min-width: 650px) {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: auto;
		grid-column-gap: 1em;
	}

	@media (min-width: 1200px) {
		max-width: 1200px;
		grid-column-gap: 2em;
	}
}

.grid--career {
	display: grid;
}

.about__content {
	width: 100%;
}

/*= Brand
===========================================*/
.brand {
	background: $primary-colour;
	padding: 28rem 0;
	position: relative;
	overflow: hidden;
}

.brand__logo {
	width: 80%;
	margin: 0 auto;
	display: block;
	overflow: visible !important;

	@media (min-width: 650px) {
		grid-column: 4 / 10;
		width: 100%;
	}

	@media (min-width: 1200px) {
		grid-column: 5 / 9;
	}

	path {
		fill: $dark-colour;
	}
}

#eyes-open {
	animation: blink 1.5s infinite;
	animation-fill-mode: backwards;
}

#face {
	animation: reveal 750ms 40ms ease-in-out;
	animation-fill-mode: backwards;
}

#filippo_f {
	animation: reveal 700ms 80ms ease-in;
	animation-fill-mode: backwards;
}

#filippo_p {
	animation: reveal 780ms 100ms ease-in-out;
	animation-fill-mode: backwards;
}
//screen-loader
@keyframes blink {
    0% {
        opacity: 1;
    }

    90% {
    	opacity: 1;	
    }

    100% {
        opacity: 0;
    }    
}

//Move up and fade in
@keyframes reveal {
	0% {
			opacity: 0.5;
			transform: translateY(800px);
	}

	20% {
		transform: translateY(800px);
	}

	40% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-150px);
	}

	

	100% {
			transform: translateY(0);
	}    
}

.designing_since_wrapper {
	font-weight: 900;
	font-size: 5rem;
	text-transform: uppercase;
	text-align: center;
	line-height: 1em;
	padding: 6rem 0;
	transform: translateX(-0.2em);

	@media (min-width: 650px) {
		padding: 8rem 0;
		font-size: 10rem;
	}

	@media (min-width: 1200px) {
		padding: 12rem 0;
		font-size: 16rem;
	}
}

.designing_since {
	text-shadow: -1px 0  $primary-colour, 0 1px  $primary-colour, 1px 0  $primary-colour, 0 -1px  $primary-colour;
	color: $background;
	overflow: hidden;
	animation: scrollfromleft auto ease-in-out forwards;
}

.left_animation {
	view-timeline-name: --design-scroll; /* create the element (--image-scroll) */
  	view-timeline-axis: block;
	animation-timeline: --design-scroll;
}

@keyframes scrollfromleft { 
	0% {
		transform: translateX(-100px);
	}

	100% {
		transform: translateX(0px);
	}
}

.since2004_text {
	display: block;
	animation: scrollfromright auto ease-in-out forwards;
}

.right_animation {
	view-timeline-name: --design-scroll; /* create the element (--image-scroll) */
  	view-timeline-axis: block;
	animation-timeline: --design-scroll;
}

@keyframes scrollfromright { 
	0% {
		transform: translateX(100px);
	}

	100% {
		transform: translateX(0px);
	}
}


.year {
	color: $primary-colour;
	margin-left: 0.25em;
}

.about_section {
	max-width: 1600px;
	margin: 0 auto;
	
	@media (min-width: 800px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	@media (min-width: 1300px) {
		align-items: flex-start;
	}
}

.about_content {
	padding: 0 24px;

	@media (min-width: 800px) {
		width: 600px;
	}

	@media (min-width: 1000px) {
		padding: 0 64px;
	}
}

.about_text {
	margin-bottom: 24px;
	color: $white;

	@media (min-width: 1000px) {
		font-size: 2.75rem;
		margin-bottom: 40px;
	}
}

.company_link {
	text-underline-offset: 4px;

	&:hover {
		text-decoration: none;
	}

	&:active {
		text-decoration: underline;
	}
}

.about_links {
	border-bottom: 1px solid rgba($text-light, 0.2);
}

.about_links__link_wrapper {
	list-style: none;
	margin: 0;
	left: 0;
	border-top: 1px solid rgba($text-light, 0.2);
}

.about_links__link {
	display: block;
	text-decoration: none;
	padding: 16px 0;
	color: $white;
	display: flex;
	align-items: center;

	@media (min-width: 650px) {
		font-size: 2.4rem;
	}

	.icon {
		opacity: 0;
		transition: all 200ms ease-in-out;
		border-color: $interactive;

		path {
			transition: stroke 200ms ease-in-out;
		}
	}

	&:hover .icon {
		opacity: 1;
		transform: translateX(8px);

		path {
			stroke: $interactive--dark;
		}
	}
}

.vision_wrapper {
	text-shadow: -1px 0 rgba($text-light, 0.2), 0 1px rgba($text-light, 0.2), 1px 0 rgba($text-light, 0.2), 0 -1px rgba($text-light, 0.2);
	color: $background;
	text-align: right;
	font-size: 7rem;
	line-height: 1em;
	font-weight: 900;
	margin: 40px 24px 40px 0;
	display: block;

	@media (min-width: 650px) {
		font-size: 14rem;
		animation: scrollfrombottom auto ease-in-out forwards;
	}

	@media (min-width: 800px) {
		font-size: 8rem;
		margin: 0 40px 0 0;
	}

	@media (min-width: 1000px) {
		font-size: 10rem;
	}

	@media (min-width: 1300px) {
		font-size: 18rem;
		margin: 122px 40px 64px 0;
	}
}

.vision_word {
	display: block;
}

.up_animation {
	view-timeline-name: --vision-scroll;
	view-timeline-axis: block;
	animation-timeline: --vision-scroll;
}

@keyframes scrollfrombottomMobile { 
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}

	60% {
		transform: translateX(100px);
		opacity: 1;
	}

	100% {
		transform: translateX(100px);
		opacity: 1;
	}
}

@keyframes scrollfrombottom { 
	0% {
		transform: translateY(-200px);
		opacity: 0;
	}

	60% {
		transform: translateY(0px);
		opacity: 1;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}
