@import "../../sass/components/colours";

.featured-image-wrapper {
    position: relative;
    padding: 46% 20px 0;
    overflow: hidden;

    .post__content-wrapper & {
        margin-bottom: 1.5em;
    }

    @media (min-width: 900px) { 
        box-sizing: border-box;
        padding-top: 46%;
    }
}

.featured-image-wrapper--not-loaded {
    animation: load 1.5s infinite ease-in-out;
    animation-fill-mode: backwards;

    &:after {
        opacity: 0;
    }
}

@keyframes load {
  0% { opacity: 0.4; }
  20% { opacity: 1; }
  100% { opacity: 0.4; }  
}

.featured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: inline-block;
    transition: opacity 0.3s ease-in-out;

    .content & {
        padding: 0;
    }
}

.featured-image--loaded {
    opacity: 1;
}

.featured-image--not-loaded {
    opacity: 0;
}