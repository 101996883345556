@import "../components/colours";

.post__image {
    margin: 0 0 0 -5%;
    width: calc(110%);


    @media (min-width: 900px) { 
        margin-top: 0;
    }
}

.post__title-wrapper {
	margin: 2.4rem auto 2.6rem;

    @media (min-width: 900px) { 
			margin: 3.2rem auto 4.2rem;
    }
}

.post__title-wrapper-without-image {
	margin: 2.4rem auto 2.6rem;

    @media (min-width: 900px) { 
			margin: 12rem auto 4.2rem;
    }
}

.post__title {
	font-weight: 400;
	color: $text;
	font-size: 4rem;
	margin-bottom: 0;

	.content & {
		margin-top: 0;
		margin-bottom: 0;
	}

	@media (min-width: 650px) {
		font-size: 6rem;
	}
}

.post__meta {
	display: flex;
	align-items: center;
	justify-content: flex-start;	
	gap: 16px;
}

.post__meta-content {
	font-size: 1.5rem;
	font-weight: 100;
	display: flex;
	align-items: center;
	gap: 6px;
	margin-top:8px;
	color: $white;

	svg {
		transform: translateY(-1px);
	}
}

.content h1.post__title--small {
	font-size: 1.6rem;
	font-weight: normal;
	color: $text-light;
	display: block;
	margin: 3.6rem 0 5px;
	width: 100%;
	letter-spacing: 0.1rem;
	transform: translateX(4px);
	font-weight: 200;

	@media (min-width: 650px) {
		transform: translateX(6px);
	}
}

.post__title-wrapper--portfolio {
	flex-direction: column;
}