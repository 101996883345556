@import "../../sass/components/colours";

/*= Thank you
=========================================*/
.thanks {
	background: $dark-colour;
	color: $white;
	text-align: center;
	overflow: hidden;
	display: flex;
	gap: 1.6rem;
	flex-direction: row;

	@media (min-width: 650px) {
		padding: 1.6rem 0; 
	}
}

.social-icon {
	padding: 0.5rem 2rem 0.5rem 2rem;
	color: $white;
	text-decoration: none;
	display: flex;
	justify-content: center;
	border: 4px solid $white;
	font-weight: bold;
	

	svg {
	 width: 3.2rem;
	 height: 3.2rem;
	 display: inline-block;
	 margin-right: 1.6rem;
	}

	path {
		fill: $white;
	}

	&:hover {
		background: $interactive;
		border-color: $interactive;
		color: $background;

		path {
			fill: $background;
		}
	}
}

.social-icon--linkedin {
	svg {
		transform: scale(0.6) translate(1px, -2px);
	}
}
