$primary-colour: #FEDDFF;
$dark-colour: #262326;
$text-light: #E1E1E1;
$background: #262326;
$background__stroke: #424242;
$white: #fff;
$text: #fff;
$interactive: #FEDDFF;
$interactive--light: #FEDDFF;
$interactive--dark: #FCA8FF;
$black: #000;
