@import "../components/colours";

/*= title
================================================== */
.porfolio__title {
	padding: 20px 20px 0 20px;
	margin: 0;
	font-size: 1.5rem;
	text-transform: uppercase;
	letter-spacing: 1px;

	@media (min-width: 650px) {
		display: none;
	}

	span {
		margin-right: 1rem;
	}
}

/*= Eye candy
================================================== */
.portfolio {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(6, 0.5fr);
	grid-column-gap: 1.6rem;
	grid-row-gap: 1.6rem;
	padding: 20px;

	@media (min-width: 650px) {
		grid-row-gap: 0rem;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: repeat(23, 7vw);
		padding: 0px;
	}
}

.portfolio__cell {
	overflow:hidden;
	position: relative;

	.porfolio__image {
		object-fit: cover;	
		height: 100%;
	}
}

@media (min-width: 650px) {
	.feature5 .porfolio__image {
		object-fit: inherit;
		height: auto;
	}
}

.portfolio-lettering {
	display: none;
	font-weight: 900;
	text-shadow: -1px 0 rgba($text-light, 0.5), 0 1px rgba($text-light, 0.5), 1px 0 rgba($text-light, 0.5), 0 -1px rgba($text-light, 0.5);
	color: $background;
	font-size: 8rem;
	overflow: hidden;
	text-transform: uppercase;

	@media (min-width: 650px) {
		font-size: 8rem;
		display: block;
	}

	@media (min-width: 1000px) {
		font-size: 10rem;
	}

	@media (min-width: 1400px) {
		font-size: 15rem;
	}
}

.portfolio__cell--image {
	background: $interactive;
}

.portfolio__link {
	background: $interactive;

	.icon--chevron {
		opacity: 0;
		transition: all 200ms ease-in-out;
	}

	&:hover .porfolio__image {
		filter: grayscale(1);
		mix-blend-mode: multiply;
		opacity: 0.7;
	}

	&:hover .icon--chevron {
		display: inline-block;
		transform: translateX(8px);
		opacity: 1;
	}
}

.porfolio__image {
	display: block;
	width: 100%;
}

.portfolio__name {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 8px 12px;
	background: rgba($dark-colour,0.6);
	color: $white;
	font-size: 0.75em;
	display: flex;
	align-items: center;

	span {
		display: none;;
	}

	@media (min-width: 800px) {
		font-size: 1em;
		
		span {
			display: inline;
			margin-left: 0.375em;
		}
	}
}

.feature1 {
	grid-row: 1/3;
	aspect-ratio: 195/166;

	@media (min-width: 650px) {
		aspect-ratio: auto;
	}
}

.feature1,
.feature4 {
	grid-column: 1/3;
}

.feature2,
.feature3 {
	aspect-ratio: 224/191;

	@media (min-width: 650px) {
		aspect-ratio: auto;
	}
}

.feature4 {
	aspect-ratio: 460/189;

	@media (min-width: 650px) {
		aspect-ratio: auto;
	}
}

.feature5,
.feature6 {
	grid-row: 5/7;

	aspect-ratio: 111/197;

	@media (min-width: 650px) {
		aspect-ratio: auto;
	}
}

.porfolio__image--desktop {
	display: none;
}

@media (min-width: 650px) {
	.porfolio__image--desktop {
		display: block;
	}

	.porfolio__image--mobile {
		display: none;
	}	

	.feature1 {
		grid-column: 1/8;
		grid-row: 1/7;
	}

	.portfolio-lettering--web {
		grid-column: 10/13;
		grid-row: 1/4;
		justify-self: end;
		align-self: center;
	}

	.feature2 {
		grid-column: 2/5;
		grid-row: 8/12;
	}

	.portfolio-lettering--brand {
		grid-column: 6/13;
		grid-row: 8/10;
		align-self: center;
		transform: translateX(-10px);
	}

	.feature3 {
		grid-column: 9/13;
		grid-row: 4/8;
	}

	.feature4 {
		grid-column: 1/5;
		grid-row: 13/17;
	}

	.feature5 {
		grid-column: 6/13;
		grid-row: 10/18;
		overflow: hidden;
	}

	.portfolio-lettering--product {
		grid-column: 1/13;
		text-align: center;

		grid-row: 17/22;
		align-self: center;
	}

	.feature6 {
		grid-column: 1/13;
		grid-row: 21/25;
	}
}

/*= Grid
================================================== */
.grid.grid--work {
	width: 100%;
}

/*= Tales from the cript
================================================== */
.case-studies {
	display: none;
}

.work-title--case-studies {
	margin: 2.4rem 20px 0;
	@media (min-width: 650px) {
		margin: 6.4rem 0 0;
	}
}

.tales {
	background: $dark-colour;
	padding: 4em 0 3em; 
	color: $white;
}


.content .tales-title {
	color: $white;
	margin-top: 0;

	@media (min-width: 650px) {
		grid-column: 2/13;
	}

	@media (min-width: 1200px) {
		grid-column: 1/13;
	}
}

.grid--tales {
	grid-column-gap: 1em;
}

@media (min-width: 650px) {
	.tale:nth-child(2n+1) {
		grid-column: 1/7;

		.post-listing { 
			margin-left: 20px;
		}
	}

	.tale:nth-child(2n+2) {
		grid-column: 7/13;

		.post-listing { 
			margin-right: 20px;
		}
	}
}

@media (min-width: 700px) {
	.tale:nth-child(2n+1) {
		.post-listing { 
			margin-left: 0px;
		}
	}

	.tale:nth-child(2n+2) {
		.post-listing { 
			margin-right: 00px;
		}
	}
}

.tale {
	@media (min-width: 650px) {
		.post-listing {
			border: 0;
		}
	}

	.featured-image-wrapper {
		border: 0;	
	}

	@media( min-width: 650px ){
		.post-listing__image-wrapper {
			margin-bottom: 10px;
		}		
	}	
}