@import "../../sass/components/colours";

%base-button {
    border: 2px solid $white;
    color: $white;
    text-decoration: none;
    padding: 0.5rem;
    font-weight: bold;
    padding: 5px 12px;

    &:hover {
        background: $interactive;
        border-color: $interactive;
        color: $background;
    }

    &:active {
        background: $dark-colour;
        border-color: $dark-colour;
        color: $background;
    }
}

.button {
    @extend %base-button;
}

.button--primary {
    @extend %base-button;
    padding: 10px 18px;
    border-width: 4px;
    display: inline-block;
}
