@import "../components/colours";

.page-title {
	font-size: 4.2rem;
	font-weight: 900;
	margin: 0;
	padding: 20px 20px 0;
	letter-spacing: 0;
	text-align: left;

	@media (min-width: 650px) {
		width: 100%;
		padding: 20px 20px 0;
	}

	@media (min-width: 700px) {
		width: 100%;
		padding: 40px 0 0px;
	}
}

.post-listing__wrapper {
	@media (min-width: 700px) {
	    width: 80%; 
	    margin: 20px auto;
	    max-width: 1200px;
    }
}

.pagination {
	position: relative;
	display: flex;
	justify-content: center;
	margin: 40px 0;
}

.pagination__item {
	list-style: none;
	border-left: 1px solid $text-light;

	&:first-child{
		border: 0;
	}
}

.pagination__link {
	display: block;
	padding: 0px 15px;	
	line-height: 1.3em;
	text-decoration: none;
	color: $text-light;

	&:hover {
		color: $interactive;
	}
}

.pagination__link--active,
.pagination__link--active:hover {
	font-weight: bold;
	color: $text;
	background: none;
}

